@layer base {
  .main {
    font-size: var(--sk-typography-body-2-regular-font-size);
    /* Override Spark to be compliant with the initial design */
    font-weight: var(--sk-typography-body-2-bold-font-weight);
    line-height: var(--sk-typography-body-2-bold-line-height);
}

.searchParamsField {
    font-size: var(--sk-font-size-1);
    font-weight: var(--sk-typography-body-2-regular-font-weight);
}

@media (--sk-medium-viewport) {
    .searchParamsField {
        font-size: var(--sk-font-size-2);
        line-height: var(--sk-line-heights-3);
    }
}

.searchParamsField:not(:last-child)::after {
    content: "•";
    margin: 0 var(--sk-space-8);
    color: var(--sk-color-grey-700);
}

.searchParamItem:not(:last-child)::after {
    content: ", ";
}
}