@layer base {
  .main {
  padding: var(--sk-space-20) var(--sk-space-40);
  background-color: var(--themeHeaderTextColor, var(--sk-color-black));
  color: var(--sk-color-white);
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.links {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0 0 var(--sk-space-20) 0;
  list-style-type: none;
  border-bottom: 1px solid rgba(255 255 255 / 30%);
}

.item {
  margin: 0 var(--sk-space-16);
}

.link {
  display: inline-block;
  padding: var(--sk-space-12) 0;
  color: var(--themeHeaderColor, var(--sk-color-white));
  font-size: var(--sk-typography-caption-1-font-size);
  line-height: var(--typo-body-2-line-height);
  text-decoration: none;
}

@media (--medium-viewport) {
  .main {
    padding: var(--sk-space-4) var(--sk-space-40);
  }

  .item:first-child {
    margin-left: 0;
  }

  .item:last-child {
    margin-right: 0;
  }

  .links {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    padding-bottom: var(--sk-space-4);
  }

  .link {
    padding: var(--sk-space-16) 0;
  }
}

@media (width >= 830px) {
  .main {
    padding: var(--sk-space-8) var(--sk-space-40);
  }

  .wrapper {
    flex-direction: row;
  }

  .links {
    padding-bottom: 0;
    border-bottom: 0;
  }
}

@media (--sk-large-viewport) {
  .wrapper {
    max-width: 1312px;
    margin: auto;
  }
}

}