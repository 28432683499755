@layer modules, ui, base;
@layer base {
  .Copyright_main__y29Q2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: var(--sk-space-32);
  padding-bottom: var(--sk-space-12);
  color: var(--themeHeaderColor, var(--sk-color-white));
  font-size: var(--sk-typography-caption-1-font-size);
  line-height: 1.125rem;
  text-align: center;
}

.Copyright_websiteGoal__h4c1K {
  position: relative;
  padding-left: var(--sk-space-12);
}

@media (min-width: 48rem) {
  .Copyright_main__y29Q2 {
    flex-direction: row;
    justify-content: center;
    padding-top: var(--sk-space-20);
  }

  .Copyright_websiteGoal__h4c1K::before {
    content: " - ";
    position: absolute;
    left: var(--sk-space-2);
  }
}

@media (min-width: 830px) {
  .Copyright_main__y29Q2 {
    padding-top: 0;
    padding-bottom: 0;
  }
}

}
@layer base {
  .Footer_main__xRCO_ {
  padding: var(--sk-space-20) var(--sk-space-40);
  background-color: var(--themeHeaderTextColor, var(--sk-color-black));
  color: var(--sk-color-white);
}

.Footer_wrapper__y7TY1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Footer_links__SO_Dk {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0 0 var(--sk-space-20) 0;
  list-style-type: none;
  border-bottom: 1px solid rgba(255 255 255 / 30%);
}

.Footer_item__jynpK {
  margin: 0 var(--sk-space-16);
}

.Footer_link__rWjDJ {
  display: inline-block;
  padding: var(--sk-space-12) 0;
  color: var(--themeHeaderColor, var(--sk-color-white));
  font-size: var(--sk-typography-caption-1-font-size);
  line-height: var(--typo-body-2-line-height);
  text-decoration: none;
}

@media (min-width: 48rem) {
  .Footer_main__xRCO_ {
    padding: var(--sk-space-4) var(--sk-space-40);
  }

  .Footer_item__jynpK:first-child {
    margin-left: 0;
  }

  .Footer_item__jynpK:last-child {
    margin-right: 0;
  }

  .Footer_links__SO_Dk {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    padding-bottom: var(--sk-space-4);
  }

  .Footer_link__rWjDJ {
    padding: var(--sk-space-16) 0;
  }
}

@media (min-width: 830px) {
  .Footer_main__xRCO_ {
    padding: var(--sk-space-8) var(--sk-space-40);
  }

  .Footer_wrapper__y7TY1 {
    flex-direction: row;
  }

  .Footer_links__SO_Dk {
    padding-bottom: 0;
    border-bottom: 0;
  }
}

@media (min-width: 1312px) {
  .Footer_wrapper__y7TY1 {
    max-width: 1312px;
    margin: auto;
  }
}

}
@layer base {
  .Header_main__idzyq {
  position: relative;
  padding: var(--sk-space-12) var(--sk-space-16);
  background-color: var(--sk-color-white);
  box-shadow: 0 4px 8px 0 var(--sk-color-grey-100);
}

.Header_wrapper__MDGPa {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Header_dropdownsWrapper__enCnh {
  display: flex;
  align-items: center;
  gap: var(--sk-space-16);
}

@media (min-width: 48rem) {
  .Header_main__idzyq {
    padding: var(--sk-space-12) var(--sk-space-40);
  }
}

@media (min-width: 1312px) {
  .Header_wrapper__MDGPa {
    max-width: 1312px;
    margin: auto;
  }
}

}
@layer base {
  .layout_main__t4cyM {
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
}

.layout_container__0oSRS {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: var(--sk-space-16) 0;
  background-color: var(--sk-color-grey-025);
}

.layout_wrapper__9HjEl {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 var(--sk-space-16);
  gap: var(--sk-space-40);
}

@media (min-width: 768px) {
  .layout_wrapper__9HjEl {
    padding: 0 var(--sk-space-40);
  }

  .layout_container__0oSRS {
    padding: var(--sk-space-32) 0;
  }
}

@media (min-width: 1312px) {
  .layout_container__0oSRS {
    align-items: center;
  }

  .layout_wrapper__9HjEl {
    width: 100%;
    max-width: 1312px;
  }
}

}
@layer base {
  .Push_main__PDwqB {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  padding: var(--sk-space-16) var(--sk-space-24);
  border-radius: var(--sk-radius-12);
  outline: 0;
  color: inherit;
  text-decoration: none;
  gap: var(--sk-space-8);
}

.Push_main__PDwqB::before,
.Push_main__PDwqB::after {
  content: "";
  position: absolute;
  border-radius: inherit;
}

.Push_main__PDwqB::before {
  z-index: var(--z-reset-in-the-current-stacking-context);
  inset: 0;
  background: linear-gradient(90.31deg, rgb(100 60 186 / 50%) 0.53%, rgb(255 0 153 / 50%) 99.47%);
}

.Push_main__PDwqB::after {
  z-index: var(--z-reset-in-the-current-stacking-context);
  border-radius: calc(var(--sk-radius-12) - var(--sk-size-2));
  background: linear-gradient(90.31deg, #eae6f3, #fae1ef);
  inset: var(--sk-size-2);
}

.Push_main__PDwqB:active::before,
.Push_main__PDwqB:hover::before,
.Push_main__PDwqB:focus-visible::before {
  background: linear-gradient(90.31deg, #643cba 0.53%, #ff0099 99.47%);
}

.Push_main__PDwqB:focus-visible {
  box-shadow:
    var(--sk-ressource-focus-ring-shadow-light-shadow-3),
    var(--sk-ressource-focus-ring-shadow-light-shadow-2);
}

.Push_content__hmI9m, .Push_icon__mS1oI {
  z-index: var(--z-up-in-the-current-stacking-context);
}

.Push_content__hmI9m {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-8);
  place-items: flex-start start;
}

.Push_content__hmI9m .Push_badge__N8UxZ {
  align-self: start;
}

.Push_icon__mS1oI {
  min-width: var(--sk-size-20);
  padding: var(--sk-space-12);
}
}
@layer base {
  .AI_badge__eONwN {
  background: linear-gradient(90.31deg, #643cba 0.53%, #ff0099 99.47%);
  color: var(--sk-color-white);
}

.AI_button__F_G82,
.AI_heading__aZk8P {
  background: linear-gradient(90.31deg, #643cba 0.53%, #ff0099 99.47%);
  color: var(--sk-color-white);
}

.AI_heading__aZk8P {
  padding: var(--sk-space-2) var(--sk-space-4);
  border-radius: var(--sk-radius-4);
}

.AI_button__F_G82:hover,
.AI_button__F_G82:active,
.AI_button__F_G82:focus-visible {
  background: linear-gradient(90.31deg, #513095 0.53%, #cb017a 99.47%);
}
}
@layer base {
  .ArchiveCandidacyButton_main__PrtUK {
  margin-top: var(--sk-space-24);
}

}
@layer base {
  .StudiesInfoForm_main__7ru3W {
  position: relative;
}

.StudiesInfoForm_header__XqS0r {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-8);
  padding: var(--sk-space-40) 0;
  text-align: center;
}

.StudiesInfoForm_footer__UlsrO {
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  justify-content: center;
  width: 100%;
  border: none;
  background-color: var(--sk-color-white);
}

.StudiesInfoForm_footer__UlsrO button {
  width: calc(100% - calc(var(--sk-space-16) * 2));
  margin: var(--sk-space-16) 0;
}

.StudiesInfoForm_fields__GgUN7 {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-24);
  margin-bottom: var(--sk-space-56);
  padding-bottom: 16vh;
}

@media (min-width: 768px) {
  .StudiesInfoForm_highlighted_choices__wFick {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .StudiesInfoForm_highlighted_choices__wFick > :first-child {
    grid-column: 1 / 3;
  }

  .StudiesInfoForm_fields__GgUN7 {
    padding-bottom: var(--sk-space-72);
  }
}

}
@layer base {
  .DropdownContent_main__bodyText__B7zat {
  font-size: var(--sk-typography-body-1-regular-font-size);
  font-style: normal;
  font-weight: var(--sk-typography-body-1-regular-font-weight);
  line-height: var(--sk-typography-body-1-regular-line-height);
}

.DropdownContent_main__dropdownContent__LQQZo {
  padding: var(--sk-space-8) var(--sk-space-16);
  color: var(--sk-color-grey-500);
  font-family: var(--sk-font-families-poppins);

  text-align: center;
}

.DropdownContent_main__dropdownItem__XseAn {
  width: 100%;
  padding: var(--sk-space-8) var(--sk-space-16);
  border: none;
  background-color: var(--sk-color-white);
  color: var(--sk-color-black);
  font-family: var(--sk-font-families-poppins);
  text-align: left;
  word-wrap: break-word;
  white-space: normal;
}

.DropdownContent_main__dropdownItem__XseAn:hover,
.DropdownContent_main__dropdownItem__XseAn:focus,
.DropdownContent_main__dropdownItem__XseAn:active {
  outline: none;
  background-color: var(--sk-color-grey-050);
  cursor: pointer;
}

.DropdownContent_main__dropdownAbroadOption__ytDdL {
  display: flex;
  gap: 8px;
  align-items: center;
}

.DropdownContent_main__error__kHScH {
  color: var(--sk-color-error-500);
}

.DropdownContent_main__abroadIcon__omGm0 {
  width: var(--sk-space-20);
  height: var(--sk-space-20);
}
}
@layer base {
  .RadiusFilter_main__INBOe {
  --field-height: 48px;
  position: relative;
  height: var(--field-height);
}

.RadiusFilter_main__INBOe::before {
  content: "";
  position: absolute;
  z-index: var(--z-up-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
  top: 50%;
  bottom: auto;
  left: 0;
  width: 1px;
  height: 50%;
  transform: translateY(-50%);
  background: var(--sk-color-grey-100);
}

.RadiusFilter_select__bwzzz {
  height: var(--field-height);
}

.RadiusFilter_select__bwzzz span {
  font-size: var(--sk-font-size-2);
}

.RadiusFilter_select__bwzzz [class*="sk-Select_selectIcon"] {
  display: none;
}

.RadiusFilter_select__bwzzz [class*="sk-Select_dropdownContainer"] {
  height: 100%;
}

.RadiusFilter_select__bwzzz [class*="Dropdown_main__options-list-wrapper"] {
  right: 0;
  width: 140%;
}

.RadiusFilter_selectField___tMhb {
  width: auto;
  height: 100%;
  border: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
}

.RadiusFilter_selectField___tMhb:active,
.RadiusFilter_selectField___tMhb:focus,
.RadiusFilter_selectField___tMhb:focus-visible,
.RadiusFilter_selectField___tMhb:hover {
  background-color: var(--sk-color-grey-050);
}

@media (min-width: 768px) {
  .RadiusFilter_main__INBOe {
    --field-height: 56px;
  }

  .RadiusFilter_main__INBOe .RadiusFilter_selectField___tMhb {
    border-radius: 0 var(--sk-radius-16) var(--sk-radius-16) 0 !important;
  }
}

}
@layer base {
  .UnderlineShape_underlineShape__u_wIz {
  position: relative;
}

.UnderlineShape_underlineShape__text__hJkSu {
  position: relative;
  z-index: var(--z-up-in-the-current-stacking-context);
}

.UnderlineShape_underlineShape__shape__sVajf {
  --underline-side-overflow: 5px;
  position: absolute;
  bottom: -3px;
  left: calc(var(--underline-side-overflow) * -1);
  width: calc(100% + calc(2 * var(--underline-side-overflow)));
  min-width: 20px;
  height: 65%;
}

}
@layer base {
  .AlgoliaLocationFilter_main__x2b_R {
  display: flex;
  flex: 1;
}

@media (max-width: 767px) {
  .AlgoliaLocationFilter_main__x2b_R {
    flex: 1.5;
  }
}

}
@layer base {
  /* Filters Layout  */
.Layout_filtersLayout__8EF_6 {
  position: relative;
  margin-top: var(--sk-space-8);
}

@media (min-width: 768px) {
  .Layout_filtersLayout__8EF_6 {
    margin-top: var(--sk-space-16);
  }
}

/* Filters Container  */
.Layout_filtersContainer__j19qC {
  display: flex;
  /*
    These position and z-index stack this element and its descendants above all these positioned elements:
    - the results count
    - the results sort
    - the "save this search" button
    - the job ad cards
  */
  position: relative;
  z-index: calc(var(--z-above-ui-kit-cards) + 1);
  flex-direction: column;
  padding: var(--sk-space-8);
  border-radius: var(--sk-radius-24);
  background-color: var(--sk-color-black);
  gap: var(--gap-size);
  --gap-size: var(--sk-space-8);
}

@media (min-width: 768px) {
  .Layout_filtersContainer__j19qC {
    --gap-size: var(--sk-space-16);
    flex-flow: column wrap;
    padding: var(--sk-space-16);
  }
}

/* Results Layout */
.Layout_resultsLayout__QR7x3 {
  padding-top: var(--sk-space-56);
  padding-bottom: var(--sk-space-32);
}

}
@layer base {
  .SchoolBadge_main__ZZ30J {
  display: flex;
  align-items: center;
  gap: var(--sk-space-12);
}

}
@layer base {
  .SavedJobAds_headingContainer__fopwU {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.SavedJobAds_jobAdsContainer__npiNU {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: var(--sk-space-16);
  margin: 0;
  padding: 0;
  list-style-type: none;
}

@media (min-width: 64rem) {
  .SavedJobAds_jobAdsContainer__npiNU {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

}
@layer base {
  .JobAdCard_main__X08Hx {
  display: flex;
  position: relative;
  height: 100%;
  padding: var(--sk-data-display-card-body-margin-top) var(--sk-data-display-card-body-margin-right)
    var(--sk-data-display-card-body-margin-bottom) var(--sk-data-display-card-body-margin-left);
  overflow: visible;
  transition: border 100ms ease-out;
  --sk-data-display-card-color-border-default-light: var(--sk-color-grey-100);
  --sk-data-display-card-body-header-gap: var(--sk-space-8);
  border-radius: var(--sk-data-display-card-radius);
}

.JobAdCard_main__X08Hx.JobAdCard_seen__p9Lxq::after {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: inherit;
  /* --sk-color-white + 40% opacity */
  background-color: rgb(255 255 255 / 40%);
}

.JobAdCard_main__X08Hx:not(.JobAdCard_vertical__HjA_d) .JobAdCard_header__kvYF_ {
  margin-top: var(--sk-data-display-card-body-header-gap);
}

.JobAdCard_main__X08Hx:not(.JobAdCard_vertical__HjA_d) .JobAdCard_header__kvYF_ .JobAdCard_companyName__Ieoi3 {
  display: none;
}

.JobAdCard_vertical__HjA_d .JobAdCard_companyInfos__pFlk1 {
  flex-direction: column;
}

.JobAdCard_vertical__HjA_d .JobAdCard_footer__Sal5O {
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.JobAdCard_body__Q9ybe {
  margin: 0;
}

.JobAdCard_footer__Sal5O {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: var(--sk-space-48);
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding: 0;
}

.JobAdCard_footer__Sal5O .JobAdCard_sponsoredBadge__N5WpN {
  align-self: center;
}

@container --sk-card (width < 448px) {
  .JobAdCard_main__X08Hx:not(.JobAdCard_vertical__HjA_d) .JobAdCard_companyInfos__pFlk1 {
    flex-direction: column;
  }
}

@container --sk-card (width >= 448px) {
  .JobAdCard_main__X08Hx:not(.JobAdCard_vertical__HjA_d) .JobAdCard_signature__1dkTK {
    align-items: flex-start;
  }

  .JobAdCard_main__X08Hx:not(.JobAdCard_vertical__HjA_d) .JobAdCard_signature__1dkTK .JobAdCard_companyName__Ieoi3 {
    display: none;
  }

  .JobAdCard_main__X08Hx:not(.JobAdCard_vertical__HjA_d) .JobAdCard_header__kvYF_ {
    margin-top: 0;
  }

  .JobAdCard_main__X08Hx:not(.JobAdCard_vertical__HjA_d) .JobAdCard_header__kvYF_ .JobAdCard_companyName__Ieoi3 {
    display: -webkit-box;
  }

  .JobAdCard_main__X08Hx:not(.JobAdCard_vertical__HjA_d) .JobAdCard_companyInfos__pFlk1 {
    flex-flow: row wrap;
  }

  .JobAdCard_footer__Sal5O {
    flex-direction: column-reverse;
    align-items: flex-end;
    margin-top: 0;
  }
}

.JobAdCard_signature__1dkTK > picture {
  padding-top: 2px;
}

.JobAdCard_link__n5lkb {
  outline: none;
  color: var(--sk-color-black);
  text-decoration: none;
}

.JobAdCard_link__n5lkb::after {
  content: "";
  position: absolute;
  /* place it above the seen filter */
  z-index: var(--z-up-in-the-current-stacking-context);
  inset: 0;
}

.JobAdCard_title__vdhrP {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  overflow-wrap: anywhere;
}

.JobAdCard_companyInfos__pFlk1 {
  display: flex;
  gap: 0 var(--sk-space-16);
}

.JobAdCard_contractInfo__98QBU {
  display: flex;
  gap: var(--sk-space-8);
}

.JobAdCard_contractInfo__98QBU > svg {
  width: var(--sk-size-20);
  min-width: var(--sk-size-20);
  height: var(--sk-size-20);
}

.JobAdCard_contractInfo__98QBU span,
.JobAdCard_companyName__Ieoi3 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  overflow: hidden;
  overflow-wrap: anywhere;
}

.JobAdCard_applicationDate__aUiXY,
.JobAdCard_easyApplyBadge__oHTOO,
.JobAdCard_sendBadge__Z4Dnn,
.JobAdCard_applyBadge__aZHpu {
  align-self: flex-start;
  margin-top: var(--sk-space-12);
}

.JobAdCard_badges__FbMmx {
  display: flex;
  flex-direction: row;
  gap: var(--sk-space-4);
}

.JobAdCard_easyApplyBadge__oHTOO path {
  stroke-width: 2px;
  stroke: var(--sk-color-black);
  fill: var(--sk-color-green-500);
}

.JobAdCard_saveJobAdButton__A58_h {
  /* raise this link above link::after content */
  position: relative;
  /* place it above the seen filter */
  z-index: var(--z-up-in-the-current-stacking-context);
}

.JobAdCard_applicationDate__aUiXY {
  display: flex;
  gap: var(--sk-space-8);
}

/* JOBAD CARDS STATES */

.JobAdCard_link__n5lkb:hover::after {
  border-radius: var(--sk-data-display-card-radius);
  outline: var(--sk-color-grey-200) 2px solid;
}

.JobAdCard_link__n5lkb:focus-visible::after {
  border-radius: var(--sk-data-display-card-radius);
  outline: none;
  background: transparent;
  box-shadow: var(--sk-ressource-focus-ring-shadow-light-shadow-3), var(--sk-ressource-focus-ring-shadow-light-shadow-2),
    var(--sk-ressource-focus-ring-shadow-light-shadow-1);
  --sk-data-display-card-color-border-default-light: var(--sk-color-grey-200);
}

}
@layer base {
  .ResultsHeader_main__SigHm {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--sk-space-12);
}

.ResultsHeader_count__eBgYs {
  display: flex;
  align-items: center;
}

.ResultsHeader_number__s2vuJ {
  margin-right: var(--sk-space-8);
}
}
@layer base {
  .RecommendedJobs_section__ObWWt {
  display: grid;
  gap: var(--sk-space-16);
}

.RecommendedJobs_list__hE70C {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: var(--sk-space-16);
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.RecommendedJobs_seeMore__95qly {
  order: 3;
  text-wrap: nowrap;
}

@media (min-width: 64rem) {
  .RecommendedJobs_section__ObWWt {
    grid-template-columns: 1fr auto;
  }

  .RecommendedJobs_list__hE70C {
    grid-column: 1 / span 2;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .RecommendedJobs_seeMore__95qly {
    grid-column-start: 2;
    order: inherit;
  }
}

}
@layer base {
  .SavedSearch_card__yiWE4 {
  --sk-data-display-card-color-border-default-light: var(--sk-color-grey-100);
  --sk-data-display-card-body-header-gap: var(--sk-space-4);
  min-width: 100%;
}

.SavedSearch_card__yiWE4:hover {
  --sk-data-display-card-color-border-default-light: var(--sk-color-grey-200);
}

.SavedSearch_link__8TKcd {
  display: inline-block;
  width: 100%;
  margin-top: var(--sk-space-16);
  text-decoration: none;
}

.SavedSearch_nbJobAdsSinceLastSeen__C1Pcm {
  align-self: baseline;
  min-width: -moz-fit-content;
  min-width: fit-content;
  margin-bottom: var(--sk-space-8);
}

.SavedSearch_title__hWX_O {
  margin-bottom: var(--sk-space-4);
  font-size: var(--sk-font-size-1); /* Override Spark to be compliant with the initial design */
  line-height: var(--sk-line-heights-3);
}

@media (min-width: 768px) {
  .SavedSearch_title__hWX_O {
    font-size: var(--sk-font-size-2); /* Override Spark to be compliant with the initial design */
  }
}

.SavedSearch_searchFilters__e3bj9 {
  /* see https://css-tricks.com/almanac/properties/l/line-clamp/ */
  display: -webkit-box;
  overflow: hidden;
  font-size: var(--sk-typography-body-2-regular-font-size); /* Override Spark to be compliant with the initial design */
  font-weight: var(--sk-typography-body-2-bold-font-weight);
  line-height: var(--sk-typography-body-2-bold-line-height);
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  text-overflow: ellipsis;
}

.SavedSearch_footer__l0ND0 {
  justify-content: center;
}

}
@layer base {
  .PageContent_main__lBKFk {
  display: flex;
  grid-column: 1 / 5;
  flex-direction: column;
  gap: var(--sk-space-40);
}

.PageContent_section__QFTta {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-16);
}

.PageContent_headingContainer__K3myO {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.PageContent_block__QEM_k {
  height: 300px;
  border-radius: var(--sk-radius-12);
  background-color: var(--sk-color-green-200);
}

@media (min-width: 768px) {
  .PageContent_main__lBKFk {
    grid-column: 1 / 9;
    padding-right: var(--sk-space-12);
  }
}

@media (min-width: 80rem) {
  .PageContent_main__lBKFk {
    grid-column: 4 / 10;
    padding: 0 var(--sk-space-24);
  }
}

}
@layer base {
  .SearchFilters_main__NjMum {
    font-size: var(--sk-typography-body-2-regular-font-size);
    /* Override Spark to be compliant with the initial design */
    font-weight: var(--sk-typography-body-2-bold-font-weight);
    line-height: var(--sk-typography-body-2-bold-line-height);
}

.SearchFilters_searchParamsField__5BgZm {
    font-size: var(--sk-font-size-1);
    font-weight: var(--sk-typography-body-2-regular-font-weight);
}

@media (min-width: 768px) {
    .SearchFilters_searchParamsField__5BgZm {
        font-size: var(--sk-font-size-2);
        line-height: var(--sk-line-heights-3);
    }
}

.SearchFilters_searchParamsField__5BgZm:not(:last-child)::after {
    content: "•";
    margin: 0 var(--sk-space-8);
    color: var(--sk-color-grey-700);
}

.SearchFilters_searchParamItem__GBgJf:not(:last-child)::after {
    content: ", ";
}
}
