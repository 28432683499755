@layer base {
  .main {
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
}

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: var(--sk-space-16) 0;
  background-color: var(--sk-color-grey-025);
}

.wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 var(--sk-space-16);
  gap: var(--sk-space-40);
}

@media (--sk-medium-viewport) {
  .wrapper {
    padding: 0 var(--sk-space-40);
  }

  .container {
    padding: var(--sk-space-32) 0;
  }
}

@media (--sk-large-viewport) {
  .container {
    align-items: center;
  }

  .wrapper {
    width: 100%;
    max-width: 1312px;
  }
}

}