@layer base {
  .main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--sk-space-12);
}

.count {
  display: flex;
  align-items: center;
}

.number {
  margin-right: var(--sk-space-8);
}
}