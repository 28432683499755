@layer base {
  .main {
  display: flex;
  grid-column: 1 / 5;
  flex-direction: column;
  gap: var(--sk-space-40);
}

.section {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-16);
}

.headingContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.block {
  height: 300px;
  border-radius: var(--sk-radius-12);
  background-color: var(--sk-color-green-200);
}

@media (--sk-medium-viewport) {
  .main {
    grid-column: 1 / 9;
    padding-right: var(--sk-space-12);
  }
}

@media (--major-viewport) {
  .main {
    grid-column: 4 / 10;
    padding: 0 var(--sk-space-24);
  }
}

}