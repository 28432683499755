@layer base {
  .main {
  display: flex;
  position: relative;
  height: 100%;
  padding: var(--sk-data-display-card-body-margin-top) var(--sk-data-display-card-body-margin-right)
    var(--sk-data-display-card-body-margin-bottom) var(--sk-data-display-card-body-margin-left);
  overflow: visible;
  transition: border 100ms ease-out;
  --sk-data-display-card-color-border-default-light: var(--sk-color-grey-100);
  --sk-data-display-card-body-header-gap: var(--sk-space-8);
  border-radius: var(--sk-data-display-card-radius);
}

.main.seen::after {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: inherit;
  /* --sk-color-white + 40% opacity */
  background-color: rgb(255 255 255 / 40%);
}

.main:not(.vertical) .header {
  margin-top: var(--sk-data-display-card-body-header-gap);
}

.main:not(.vertical) .header .companyName {
  display: none;
}

.vertical .companyInfos {
  flex-direction: column;
}

.vertical .footer {
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.body {
  margin: 0;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: var(--sk-space-48);
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding: 0;
}

.footer .sponsoredBadge {
  align-self: center;
}

@container --sk-card (width < 448px) {
  .main:not(.vertical) .companyInfos {
    flex-direction: column;
  }
}

@container --sk-card (width >= 448px) {
  .main:not(.vertical) .signature {
    align-items: flex-start;
  }

  .main:not(.vertical) .signature .companyName {
    display: none;
  }

  .main:not(.vertical) .header {
    margin-top: 0;
  }

  .main:not(.vertical) .header .companyName {
    display: -webkit-box;
  }

  .main:not(.vertical) .companyInfos {
    flex-flow: row wrap;
  }

  .footer {
    flex-direction: column-reverse;
    align-items: flex-end;
    margin-top: 0;
  }
}

.signature > picture {
  padding-top: 2px;
}

.link {
  outline: none;
  color: var(--sk-color-black);
  text-decoration: none;
}

.link::after {
  content: "";
  position: absolute;
  /* place it above the seen filter */
  z-index: var(--z-up-in-the-current-stacking-context);
  inset: 0;
}

.title {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  overflow-wrap: anywhere;
}

.companyInfos {
  display: flex;
  gap: 0 var(--sk-space-16);
}

.contractInfo {
  display: flex;
  gap: var(--sk-space-8);
}

.contractInfo > svg {
  width: var(--sk-size-20);
  min-width: var(--sk-size-20);
  height: var(--sk-size-20);
}

.contractInfo span,
.companyName {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  overflow: hidden;
  overflow-wrap: anywhere;
}

.applicationDate,
.easyApplyBadge,
.sendBadge,
.applyBadge {
  align-self: flex-start;
  margin-top: var(--sk-space-12);
}

.badges {
  display: flex;
  flex-direction: row;
  gap: var(--sk-space-4);
}

.easyApplyBadge path {
  stroke-width: 2px;
  stroke: var(--sk-color-black);
  fill: var(--sk-color-green-500);
}

.saveJobAdButton {
  /* raise this link above link::after content */
  position: relative;
  /* place it above the seen filter */
  z-index: var(--z-up-in-the-current-stacking-context);
}

.applicationDate {
  display: flex;
  gap: var(--sk-space-8);
}

/* JOBAD CARDS STATES */

.link:hover::after {
  border-radius: var(--sk-data-display-card-radius);
  outline: var(--sk-color-grey-200) 2px solid;
}

.link:focus-visible::after {
  border-radius: var(--sk-data-display-card-radius);
  outline: none;
  background: transparent;
  box-shadow: var(--sk-ressource-focus-ring-shadow-light-shadow-3), var(--sk-ressource-focus-ring-shadow-light-shadow-2),
    var(--sk-ressource-focus-ring-shadow-light-shadow-1);
  --sk-data-display-card-color-border-default-light: var(--sk-color-grey-200);
}

}