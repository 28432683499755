@layer base {
  .card {
  --sk-data-display-card-color-border-default-light: var(--sk-color-grey-100);
  --sk-data-display-card-body-header-gap: var(--sk-space-4);
  min-width: 100%;
}

.card:hover {
  --sk-data-display-card-color-border-default-light: var(--sk-color-grey-200);
}

.link {
  display: inline-block;
  width: 100%;
  margin-top: var(--sk-space-16);
  text-decoration: none;
}

.nbJobAdsSinceLastSeen {
  align-self: baseline;
  min-width: fit-content;
  margin-bottom: var(--sk-space-8);
}

.title {
  margin-bottom: var(--sk-space-4);
  font-size: var(--sk-font-size-1); /* Override Spark to be compliant with the initial design */
  line-height: var(--sk-line-heights-3);
}

@media (--sk-medium-viewport) {
  .title {
    font-size: var(--sk-font-size-2); /* Override Spark to be compliant with the initial design */
  }
}

.searchFilters {
  /* see https://css-tricks.com/almanac/properties/l/line-clamp/ */
  display: -webkit-box;
  overflow: hidden;
  font-size: var(--sk-typography-body-2-regular-font-size); /* Override Spark to be compliant with the initial design */
  font-weight: var(--sk-typography-body-2-bold-font-weight);
  line-height: var(--sk-typography-body-2-bold-line-height);
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  text-overflow: ellipsis;
}

.footer {
  justify-content: center;
}

}