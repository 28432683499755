@layer base {
  .main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: var(--sk-space-32);
  padding-bottom: var(--sk-space-12);
  color: var(--themeHeaderColor, var(--sk-color-white));
  font-size: var(--sk-typography-caption-1-font-size);
  line-height: 1.125rem;
  text-align: center;
}

.websiteGoal {
  position: relative;
  padding-left: var(--sk-space-12);
}

@media (--medium-viewport) {
  .main {
    flex-direction: row;
    justify-content: center;
    padding-top: var(--sk-space-20);
  }

  .websiteGoal::before {
    content: " - ";
    position: absolute;
    left: var(--sk-space-2);
  }
}

@media (width >= 830px) {
  .main {
    padding-top: 0;
    padding-bottom: 0;
  }
}

}