@layer base {
  .main {
  position: relative;
  padding: var(--sk-space-12) var(--sk-space-16);
  background-color: var(--sk-color-white);
  box-shadow: 0 4px 8px 0 var(--sk-color-grey-100);
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropdownsWrapper {
  display: flex;
  align-items: center;
  gap: var(--sk-space-16);
}

@media (--medium-viewport) {
  .main {
    padding: var(--sk-space-12) var(--sk-space-40);
  }
}

@media (--sk-large-viewport) {
  .wrapper {
    max-width: 1312px;
    margin: auto;
  }
}

}