@layer base {
  .section {
  display: grid;
  gap: var(--sk-space-16);
}

.list {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: var(--sk-space-16);
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.seeMore {
  order: 3;
  text-wrap: nowrap;
}

@media (--large-viewport) {
  .section {
    grid-template-columns: 1fr auto;
  }

  .list {
    grid-column: 1 / span 2;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .seeMore {
    grid-column-start: 2;
    order: inherit;
  }
}

}