@layer base {
  .headingContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.jobAdsContainer {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: var(--sk-space-16);
  margin: 0;
  padding: 0;
  list-style-type: none;
}

@media (--large-viewport) {
  .jobAdsContainer {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

}